import { useAuthStore } from "~/store/auth/auth.store";
import { RolesEnums } from "~/utils/enums/roles.enums";
import { useFeedback } from "~/composables/feedback/useFeedback";

export default defineNuxtRouteMiddleware(({ meta }) => {
  /**
   * Named Middleware, it redirects if user do not have the right role(s)
   */

  const authStore = useAuthStore();
  const feedback = useFeedback();

  const required_roles = meta.roles as RolesEnums[] | undefined;
  const user_roles = authStore.getRoles;

  if (user_roles?.includes(RolesEnums.SIMBEL_ADMIN)) {
    return;
  }

  if (required_roles) {
    if (!user_roles?.some((r) => required_roles.includes(r))) {
      feedback.error("Navigation non autorisée", "large");

      if (process.client) {
        return abortNavigation();
      } else {
        return "/";
      }
    }
  }
});
